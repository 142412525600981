<template>
<div class="subscription-container" :style="{height: innerHeight + 'px'}">
    <div class="top">

        <!-- do you have a promo code? -->
        <div class="header">
            <h2 style="margin: 0;">{{ $t('profile.subscription.title') }}</h2>
            <p>{{ $t('profile.subscription.caption') }}</p>
        </div>

        <vs-button block transparent class="plan" v-for="plan in availablePlans" :key="plan.id" :active="selectedPlan == plan" @click="() => {if(enabledFeatures['inAppPurchases'] !== false){selectedPlan = plan}}" :disabled="(currentPlan != null && plan.id != currentPlan.id) ">
                <div class="details">
                    <img src="../../assets/icons/subscription.png" />
                    <div class="info">
                        <p>{{ plan.name }}</p>
                        <span style="background: rgba(var(--vs-primary),0.8); color: white; font-weight: 500;  padding: 5px; border-radius: 5px; font-size: 0.8rem;" v-if="plan.not_purchasable === true">NON PIÙ DISPONIBILE</span>
                    </div>
                </div>
                <div class="price">
                    <p :style="{'text-decoration': showPromotion(plan) ? 'line-through' : 'none'}" style="font-weight: 600; font-size: 1.25rem;">{{(plan.price/100).toFixed(2)}}€</p>
                    <p v-if="showPromotion(plan)" style="color: rgba(var(--vs-primary)); font-weight: bold; font-size: 1.5rem;"> {{ promoPrice(plan) }}€</p>
                    <!--<p>/{{ $t('common.year') }}</p>-->
                </div>
        </vs-button>

        <div class="header" v-if="(lastTransaction == null || lastTransaction.status != 'ACTIVE') && enabledFeatures['inAppPurchases'] !== false && !is_ios">
            <vs-input v-model="promoCode" :placeholder="$t('profile.subscription.promo.placeholder')" block border :loading="validatePromoCodeLoading" :state="validatePromoCodeResult == null ? undefined : (validatePromoCodeResult == 'error' ? 'danger' : 'success')">
                <template #icon>
                    <img class="inactive" style="width: 32px;" src="../../assets/icons/menu/admin_coupons.png" v-show="validatePromoCodeResult != 'success'" />
                    <img class="active" style="width: 32px;" src="../../assets/icons/menu/coupons_active.png" v-show="validatePromoCodeResult == 'success'" />

                </template>
                <template #message-danger v-if="validatePromoCodeResult == 'error'">
                    <p>{{ $t('profile.subscription.promo.error') }}</p>
                </template>
            </vs-input>
        </div>
    </div>

    <p v-if="enabledFeatures['inAppPurchases'] === false" style="padding: 10px; border: 1px solid rgba(var(--vs-primary), 0.5); border-radius: 5px; background: rgba(var(--vs-primary), 0.01); color: rgba(var(--vs-primary)); font-size: 0.8rem;">
        {{ $t('profile.subscription.notAvailable') }}
    </p>

    <div class="bottom">
        <div style="display: flex; justify-content: center;">
            <img src="../../assets/images/subscription_choose.png" style="width: 50%;" />
        </div>
        <template v-if="hasActiveSubscription">
            <vs-button v-if="boughtFromApple" size="large" border @click="cancelAppleSubscription">{{ $t('profile.subscription.cancellation.manage').toUpperCase() }}</vs-button>

            <vs-button v-else size="large" border @click="cancelSubscription">{{ $t('profile.subscription.cancellation.button').toUpperCase() }}</vs-button>
        </template>
        <vs-button v-if="canContinue" size="large" @click="goToInvoicing" :disabled="selectedPlan.not_purchasable === true">{{ $t('common.continue').toUpperCase() }}</vs-button>
    </div>

</div>
</template>

<script>
import { Browser } from '@capacitor/browser';
import { Device } from '@capacitor/device';
import cancelSubscriptionDialog from '../dialogs/CancelSubscriptionDialog.vue';
import { GlobalEventEmitter } from '@/utils/GlobalEventEmitter'
import { apiCall } from '../../utils/ApiMiddleware';
export default {
    name: "subscription",
    props: {
        innerHeight: {
            type: [Object, Number],
            default: null,
            required: false
        }
    },
    components: {},
    data() {
        return {
            lastTransaction: null,

            validatePromoCodeLoading: false,
            validatePromoCodeTimeout: null,
            validatePromoCodeResult: null,
            validatePromoCodePercent: 0,
            promoCode: '',

            plans: [],

            selectedPlan: null,
            currentPlan: null,
            
            is_ios: false

        }
    },

    watch: {

        promoCode(n) {
            if (this.validatePromoCodeTimeout != null) {
                clearTimeout(this.validatePromoCodeTimeout)
            }

            if (n.length > 0) {
                this.validatePromoCodeLoading = true;
                this.validatePromoCodeTimeout = setTimeout(() => {
                    this.validatePromoCode()
                }, 1000)
            } else {
                this.validatePromoCodeLoading = false
                this.validatePromoCodeResult = null
            }
        },

        plans() {
            if(this.lastTransaction != null && ['ACTIVE', 'DEACTIVATED'].includes(this.lastTransaction.status)) {
                let plan = this.plans.find(p => p.id == this.lastTransaction.plan_id);
                if(!plan){ // deprecated plan
                    this.plans.push({
                        id: this.lastTransaction.plan_id,
                        name: '1-YEAR',
                        price: 499,
                        coupon: this.lastTransaction.coupon,
                        not_purchasable: true // NOT PURCHASABLE
                    })
                }
                plan = this.plans.find(p => p.id == this.lastTransaction.plan_id);
                this.selectedPlan = plan;
                this.currentPlan = plan;
            }
        }

    },

    computed: {

        availablePlans(){
            if(this.is_ios){
                return this.plans.filter(p => p.appstore_product_id != null && p.appstore_product_id != '');
            }
            return this.plans
        },
        

        hasActiveSubscription() {
            return this.lastTransaction != null && this.lastTransaction.status == 'ACTIVE'
        },

        boughtFromApple(){
            return this.lastTransaction != null && this.lastTransaction.appstore_id != null && this.lastTransaction.appstore_id != ''
        },


        canContinue() {
            return this.selectedPlan != null && !this.hasActiveSubscription && this.enabledFeatures['inAppPurchases'] !== false
        },

        enabledFeatures() {
            return this.$FEATURES.settings;
        }

        
    },

    methods: {
        cancelAppleSubscription(){
            const url = 'https://apps.apple.com/account/subscriptions?appId=6739505239';
            if(this.$IS_APP){
                Browser.open({ url });
            } else {
                window.open(url, '_blank');
            }
            
        },

        showPromotion(plan) {
            return ((
                this.validatePromoCodeResult == 'success' && this.validatePromoCodePlans.map(p => p.id).includes(plan.id)
            ) 
            || (
                this.lastTransaction != null && this.lastTransaction.coupon != null && this.lastTransaction.status == 'PENDING' && this.lastTransaction.plan.id == plan.id
            ))
        },
        promoPrice(plan){
            let lastTransaction = this.lastTransaction;
            let validatePromoCodePercent = this.validatePromoCodePercent;

            return ((plan.price/100) * (1 - ((lastTransaction != null && lastTransaction.coupon != null && lastTransaction.status == 'PENDING') ? lastTransaction.coupon.discount_percentage : validatePromoCodePercent) / 100)).toFixed(2)
        },

        async goToInvoicing() {
            var vm = this;
            // if current transaction is ACTIVE or DEACTIVATED it means that the user has already a subscription, give an error
            if (this.lastTransaction != null && ['ACTIVE', 'DEACTIVATED'].includes(this.lastTransaction.status)) {
                this.$vs.notification({
                    title: this.$t('common.messages.somethingWentWrong'),
                    text: this.$t('profile.subscription.alreadyActive'),
                    color: 'primary',
                    position: 'top-right'
                })
                return;
            }

            if(this.is_ios){
                // if is ios
                console.log('triggering purchase');
                const loading = this.$vs.loading();
                const { inAppPurchase } = require('../../utils/inAppPurchase');

                inAppPurchase(vm.selectedPlan.appstore_product_id, loading);
                return;
            }

            this.$router.push({
                name: 'Fatturazione',
                params: {
                    coupon: vm.validatePromoCodeResult == 'success' ? vm.promoCode : '',
                    plan_id: vm.selectedPlan.id,
                    plan: vm.selectedPlan,
                }
            })
        },

        async validatePromoCode() {
            // payload field: name, endpoint: /backoffice/coupons/check
            const response = await apiCall('POST', '/coupons/check', {
                name: this.promoCode,
            }, true);
            this.validatePromoCodeLoading = false;

            if (response.status == 200 && response.data.can_be_used) {
                this.validatePromoCodeResult = 'success'
                this.validatePromoCodePercent = response.data.discount_percentage
                this.validatePromoCodePlans = response.data.plans

                if (this.validatePromoCodePlans != null && this.validatePromoCodePlans.length > 0) {
                    //if selected one is not in the list, select the first one
                    if (this.selectedPlan == null || !this.validatePromoCodePlans.map(p => p.id).includes(this.selectedPlan.id)) {
                        this.selectedPlan = this.plans.find(p => p.id == this.validatePromoCodePlans[0].id)
                    }
                }
            } else {
                this.validatePromoCodeResult = 'error'
            }
        },

        async getLastTransaction() {
            const response = await apiCall('GET', '/subscriptions', {
                limit: 1,
                order: 'desc(end_time)',
                only_active: false
            }, true);
            if (response.status == 200) {
                if (response.data.data != null && response.data.data.length > 0) {
                    return response.data.data[0];
                } else {
                    return null;
                }
            }
            return false;
        },

        async getPlans() {
            const response = await apiCall('GET', '/plans', {
                only_enabled: true
            });
            if (response.status == 200) {
                return response.data.data;
            }
            return false;
        },

        async deleteSubscription() {
            const loading = this.$vs.loading();
            const response = await apiCall('DELETE', '/subscriptions');
            loading.close();
            if (response.status == 200) {
                this.$router.push({
                    name: 'Cancellazione'
                })
            } else {
                this.$vs.notify({
                    title: this.$t('common.messages.somethingWentWrong'),
                    text: this.$t('common.messages.somethingWentWrong'),
                    color: 'danger',
                    position: 'top-right'
                })
            }
            return false;
        },

        cancelSubscription() {
            GlobalEventEmitter.$emit('showDialog', cancelSubscriptionDialog, this.cancelSubscriptionCallback) // chiedo conferma per la cancellazione

        },

        cancelSubscriptionCallback(e) {
            if (e) {
                this.deleteSubscription();
            }
        }
    },

    async mounted() {
        if(this.$IS_APP){
            // if is ios 
            const deviceInfo = await Device.getInfo();
            const platform = deviceInfo.platform;
            if(platform === 'ios'){
                this.is_ios = true;
            }
        }
        this.lastTransaction = await this.getLastTransaction()
        this.plans = await this.getPlans()
    }
}
</script>
<style>
.plan .vs-button__content {
    padding:0!important;
    justify-content: space-between;
}
</style>
<style scoped>
.header {
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 100%;

}

.top {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.bottom {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.subscription-container {
    overflow-x: hidden!important;
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 20px;
    justify-content: space-between;
}

.plan {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    gap: 10px;
    border: 1px solid var(--primary);
    border-radius: 10px;
    margin: 0!important;
}



.plan .details {
    display: flex;
    align-items: center;
    gap: 10px;
}

.plan .details .info {
    display: flex;
    flex-direction: column;
}

.plan .details .info p {
    text-align: left;
}

.plan .details .info p:nth-child(1) {
    color: var(--primary) !important;
    font-size: 1.1rem;
}

.plan .price {
    display: flex;
    flex-direction: column;
}

.plan .price p:nth-child(2) {
    font-size: 0.8rem;
    font-weight: 400;
    color: var(--grey-75);
}

p {
    margin: 0;
}

/* MOBILE */
@media (max-width: 600px) {
    .subscription-container {
        padding-left: 20px;
        padding-right: 20px;
        box-sizing: border-box;
        width: 100% !important;
        padding-top: 20px;
        padding-bottom: 110px;
        justify-content: flex-start;
        gap: 20px;
    }

}
</style><style>
.subscription-container .vs-input:focus~.vs-input__icon {
    transform: scale(1.1) !important;
}

.subscription-container .vs-input-parent--shadow .vs-input-content .vs-input:focus~.vs-input__icon {
    background: unset !important;
}

.subscription-container .vs-input:focus~.vs-input__label:not(.vs-input__label--placeholder):not(.vs-input__label--label) {
    opacity: 0 !important;
}
</style>
